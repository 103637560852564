// Default imports:
import"../styles/main.scss";

import gsap from"gsap";
import{ ScrollTrigger }from"gsap/ScrollTrigger";
import{ CustomEase }from"gsap/CustomEase";
export const mm = gsap.matchMedia();

gsap.registerPlugin(ScrollTrigger, CustomEase);

gsap.defaults({ ease: "ease" });
gsap.config({
    nullTargetWarn: false,
    force3D: true,
    autoSleep: 60
});

CustomEase.create("slider-ease", "0.26, 0, 0.38, 1");


window.addEventListener("DOMContentLoaded", () => {
    import("./init-scripts").then((init) => init.default());
});